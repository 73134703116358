import styled from "styled-components";

type MenuProps = {
    isOpen: boolean;
}


export const MenuLink = styled.a`
  padding: 1rem 1.5rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #1b71fc;
  transition: all 0.3s ease-in;
  font-size: 0.9rem;
  
  :hover {
    cursor: pointer;
  }
`;

export const MenuButton = styled.a`
  background-color: #1B71FC; /* Green */
  border: none;
  margin-left: 32px;
  color: white;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  -webkit-box-shadow: 1px 1px 16px 9px rgba(27,113,252,0.08);
  box-shadow: 1px 1px 16px 9px rgba(27,113,252,0.08);
  
  :hover {
    cursor: pointer;
  }

  @media (max-width: 992px) {
    margin-left: 4px;
    margin-bottom: 32px;
  }
  
`

export const Logo = styled.a`
  padding: 1rem 0;
  color: #1b71fc;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-weight: 900;
  font-size: 24px;
  
  span {
    margin-left: 8px;
  }
`;

export const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 2px;
    width: 25px;
    background: #1b71fc;
    margin-bottom: 4px;
    border-radius: 5px;
  }
  @media (max-width: 992px) {
    display: flex;
  }
`;


export const Nav = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 992px) {
    padding: 0;
    ${Logo} {
      padding: 1rem 2rem;
    }

    ${Hamburger} {
      padding: 1rem 2rem;
    }
  }
`;


export const Menu = styled.div<MenuProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 992px) {
    overflow: hidden;
    padding: 0;
    flex-direction: column;
    max-height: ${({ isOpen }) => (isOpen ? "410px" : "0")};
    background-color: #f7f7f7;
    transition: max-height 0.3s ease-in;
    width: 100%;
  }
`;
