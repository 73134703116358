import styled from "styled-components";

export const Header = styled.header`
  margin-top: 112px;
  height: 670px;
  @media (max-width:992px) {
    margin-top: 64px;
    height: 700px;
  }
`

export const Title = styled.h1`
  font-family: "Inter";
  font-weight: 900;
  font-size: 50px;
  color: #333;
  letter-spacing: -1.5px;
  @media (max-width: 1200px) {
    font-size: 34px;
    margin-top: 32px;
  }
`

export const Subtitle = styled.h3`
  font-family: "Inter";
  margin-top: 32px;
  font-size: 20px;
  letter-spacing: -0.46px;
  color: #52616B;
  
  span {
    color: #1b71fc
  }
  
  @media (max-width: 1200px) {
    font-size: 16px;
  }
  @media (max-width:992px) {
    margin-top: 12px;
  }
`

export const LeftBox = styled.div`
  width: 100%;
  max-width: 550px;
  @media (max-width: 1200px) {
    max-width: 400px;
  }
`

export const Button = styled.a`
  background-color: #1B71FC; /* Green */
  border: none;
  color: white;
  padding: 10px 40px;
  border-radius: 8px;
  text-align: center;
  margin-top: 32px;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  -webkit-box-shadow: 1px 1px 16px 9px rgba(27,113,252,0.18);
  box-shadow: 1px 1px 16px 9px rgba(27,113,252,0.18);
  
  :hover {
    cursor: pointer;
  }
  
  @media (max-width: 992px) {
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 12px;
  }
`

export const Image = styled.img`
  width: 100%;
  max-width: 600px;
  @media (max-width: 1200px) {
    max-width: 450px;
  }
  @media (max-width: 700px) {
    max-width: 400px;
    margin-top: 16px;
  }
`
