import React, { useState } from "react";
import styled from "styled-components";
import { Hamburger, Logo, Menu, MenuButton, MenuLink, Nav } from "./styled-components.navbar";

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Nav>
            <Logo href="#">
                <img width={30} height={30} src={"/logo192.png"} />
                <span>Backpack</span>
            </Logo>
            <Hamburger onClick={() => setIsOpen(!isOpen)}>
                <span />
                <span />
                <span />
            </Hamburger>
            <Menu isOpen={isOpen}>
                <MenuLink href="">Product</MenuLink>
                <MenuLink href="">Use Cases</MenuLink>
                <MenuLink href="">Pricing</MenuLink>
                <MenuLink href="">Contact</MenuLink>

                <MenuLink href="">Login</MenuLink>
                <MenuButton href="https://app.backpackapp.io">Sign Up</MenuButton>
            </Menu>
        </Nav>
    );
};

