import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {Switch, Route} from 'react-router-dom'
import {LandingPage} from './landing'

function App() {

    const [isInApp, setIsInApp] = useState<boolean>(false)

    useEffect(() => {
        let host = window.location.host;
        let parts = host.split(".");

        if(parts.length >=3) {
            if(parts[0] === "app") {
                setIsInApp(true)
            }
        }

    }, [])

    if(isInApp) {
        return (
            <Switch>
                <Route exact path={"/"}>
                    You in are the subdomain app:)
                </Route>
            </Switch>
        )
    }

  return (
    <Switch>
      <Route exact path={"/"} component = {LandingPage} />
      <Route>
        404
      </Route>
    </Switch>
  );
}

export default App;
