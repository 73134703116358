import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button, Header, Image, LeftBox, Subtitle, Title } from './styled-components.landing.header'
import { Navbar } from './Navbar'
import {
    Background,
    Box,
    Icon,
    MainImage,
    MainSubtitle,
    MainTitle,
    SlideContainer,
} from './styled-components.landing.main'

export const LandingPage = () => {
    return (
        <div>
            <Navbar />
            <Header>
                <Container fluid>
                    <Row>
                        <Col
                            className='order-lg-1 order-2 d-flex justify-content-center justify-content-lg-end align-items-center'
                            md={12} lg={6}>
                            <LeftBox>
                                <Title>
                                    A new way to organize <br /><span
                                    style={{ color: '#1B71FC' }}>your school life</span>
                                </Title>
                                <Subtitle>
                                    Take your organization skills to the <span>next level</span> with unlimited
                                    reminders to ensure that you don’t miss that important exam coming up.
                                </Subtitle>
                                <div>
                                    <Button>Try Backpack Now</Button>
                                </div>
                            </LeftBox>
                        </Col>
                        <Col className='order-lg-2 order-1 d-flex justify-content-center' md={12} lg={6}>
                            <Image src={'/undraw_studying_s3l7.png'} alt='Drawing Of Girl Studying' />
                        </Col>
                    </Row>
                </Container>
            </Header>
            <main>
                <Background>
                    <Container fluid>
                        <SlideContainer>
                            <Row>
                                <Col className='d-flex justify-content-center justify-content-lg-end align-items-center'
                                     md={12} lg={6}>
                                    <MainImage src={'/Main-1-Agenda.png'} alt='The Agenda Screen of Backpack' />
                                </Col>
                                <Col
                                    className='d-flex justify-content-center justify-content-lg-start align-items-center'
                                    md={12} lg={6}>
                                    <Box>
                                        <Icon>
                                            <img width={40} height={40} src='/Checked-box.png' />
                                        </Icon>
                                        <MainTitle>
                                            The App That Everyone Loves.
                                        </MainTitle>
                                        <MainSubtitle>
                                            We built Backpack so that any student is able to quickly get up and running.
                                            Simply open Backpack, add an assignment, and get reminders to finish it!
                                        </MainSubtitle>
                                    </Box>
                                </Col>

                            </Row>
                        </SlideContainer>
                        <Row style={{ marginTop: 64 }} className={'d-flex justify-content-center'}>
                            <Col className='d-flex justify-content-center' md={12} lg={4}>
                                <Box isColumnBox={true}>
                                    <Icon color='#FED054'>
                                        <img width={40} height={40} src='/Checked-box.png' />
                                    </Icon>
                                    <MainTitle>
                                        Easy to view assignments
                                    </MainTitle>
                                    <MainSubtitle>
                                        No longer wonder if that assignment is due tomorrow, today, or next week.
                                        Backpack is intuitive and has multiple ways to view your
                                        assignments. </MainSubtitle>
                                </Box>
                            </Col>
                            <Col className='d-flex justify-content-center' md={12} lg={4}>
                                <Box isColumnBox={true} isMiddleBox={true}>
                                    <Icon>
                                        <img width={40} height={40} src='/Checked-box.png' />
                                    </Icon>
                                    <MainTitle>
                                        Unlimited Notifications </MainTitle>
                                    <MainSubtitle>
                                        Treat notifications like your morning alarms -- set 6 of them so that you don’t
                                        miss that wakeup time. </MainSubtitle>
                                </Box>
                            </Col>
                            <Col className='d-flex justify-content-center' md={12} lg={4}>
                                <Box isColumnBox={true}>
                                    <Icon color='#F8A5C2'>
                                        <img width={40} height={40} src='/Checked-box.png' />
                                    </Icon>
                                    <MainTitle>
                                        Powerful Recurring Assignments </MainTitle>
                                    <MainSubtitle>
                                        Have a club meeting on the first and third monday? Covered. Have to read a book
                                        and finish by the last Friday every month? We got you. </MainSubtitle>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Background>

            </main>
        </div>
    )
}
