import styled from "styled-components";

type BoxProps = {
    isMiddleBox?:boolean;
    isColumnBox?: boolean;
}

type IconProps = {
    color?: string;
}

export const MainTitle = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.46px;
  max-width: 200px;
  color: #000000;

  @media (max-width: 1200px) {
    font-size: 20px;
  }
`

export const BoxContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const MainSubtitle = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
  max-width: 300px;
  letter-spacing: -0.46px;

  color: #52616B;
  
  @media (max-width: 1200px) {
    font-size: 14px;
    max-width: 230px;
  }
`

export const Background = styled.div`
  background-color: #0BAA83;
  height: 100vh;
`

export const SlideContainer = styled.div`
  top: -100px;
  position: relative;

  @media (max-width: 1200px) {
    top: -120px;
  }

  @media (max-width: 992px) {
    top: -60px;
  }
`

export const Box = styled.div<BoxProps>`
  width: ${({ isColumnBox }) => (isColumnBox ? "385px" : "415px")};
  height: 500px;
  margin-left: 24px;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px 5px rgba(27, 113, 252, 0.15);
  border-radius: 18px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: ${({isColumnBox}) => (isColumnBox ? "center" : "") };

  @media (max-width: 1200px) {
    max-width: ${({ isColumnBox }) => (isColumnBox ? "300px" : "360px")};
    max-height: 440px;
    margin-top: 16px;
  }
  
  @media (max-width: 992px) {
    margin-left: 0px;
    max-width: 330px;
    max-height: 400px;
  }
  
  margin-top: ${({ isMiddleBox }) => (isMiddleBox ? "-64px" : "0")};
    `

export const Icon = styled.div<IconProps>`
  width: 80px;
  height: 80px;
  left: 923px;
  top: 864px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({color}) => (color ? color : "#1B71FC")};
  border-radius: 30px;
  margin-bottom: 48px;
    `

export const MainImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin-right: 24px;
  @media (max-width: 1200px) {
    max-width: 230px;
  }
  @media (max-width: 992px) {
    margin-right: 0px;
    max-width: 190px;
    margin-top: 16px;
  }
`
